import React, { Component } from 'react';
import "./coming.scss"

export default class Coming extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <div className="recontainer">
            <img className="coming-img" src="../images/01.png" alt=""/>
            <h1 className="text-center h-top">We'll be back soon!</h1>
            {/* <p className="text-center p-coming">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto provident natus dicta, fugit praesentium alias ipsum corrupti ea esse, asperiores necessitatibus deleniti minus eveniet id beatae quibusdam saepe facere. Quos.</p> */}
            {/* <p className="text-center p-coming m-05">Cheers</p>
            <p className="text-center p-coming">flightsforever team</p> */}
          </div>
        </div>
      </div>
    )
  }
}
