import React, { Component } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

export default class NavModal extends Component {
  render() {
    return (
      <div className="modal-container" data-aos="fade-in">
        <div data-aos="fade-right" className="modal-container-overlay">
          <Menu
            mode="inline"
          >
            <Menu.Item>
              <Link to="/">
                Home
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/update">
                Profile
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/faq">
                FAQ
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/privacypolicy">
                Privacy Policy
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/tos">
                T & C
              </Link>
            </Menu.Item>
          </Menu>
        </div>
        <div className="modal-container-close" onClick={() => this.props.onClose()}>

        </div>
      </div>
    )
  }
}
