import React, { Component } from 'react';
import Navbar from '../ui/navbar';
import Footer from '../ui/footer';
import { Helmet } from 'react-helmet';

export default class Privacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex"/>
        </Helmet>
        <Navbar classPass="xnav inherit-pos" />
        <section>
          <div>
            <h1 className="text-center h1-large text-color-pri mar-bottom-1 mar-t-0">Privacy Policy</h1>
          </div>
          <div className="wid-6">
            <p>Protecting your private information is our priority. This Statement of Privacy applies to https://flightsforever.com and Flights Forever and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Flights Forever include https://flightsforever.com. The Flights Forever website is a ecommerce site. By using the Flights Forever website, you consent to the data practices described in this statement.</p>
            <p className="full-weight">Collection of your Personal Information</p>
            <p>In order to better provide you with products and services offered on our Site, Flights Forever may collect personally identifiable information, such as your:</p>
            <ul>
              <li>Email address</li>
              <li>Chat (Tidio)</li>
            </ul>
            <p>If you purchase Flights Forever's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.</p>
            <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
            <p className="full-weight">Use of your Personal Information</p>
            <p>Flights Forever collects and uses your personal information to operate its website(s) and deliver the services you have requested.</p>
            <p>Flights Forever may also use your personally identifiable information to inform you of other products or services available from Flights Forever and its affiliates.</p>
            <p className="full-weight">Sharing Information with Third Parties</p>
            <p>Flights Forever does not sell, rent or lease its customer lists to third parties.</p>
            <p>Flights Forever may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Flights Forever, and they are required to maintain the confidentiality of your information.</p>
            <p>Flights Forever may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Flights Forever or the site; (b) protect and defend the rights or property of Flights Forever; and/or (c) act under exigent circumstances to protect the personal safety of users of Flights Forever, or the public.</p>
            <p className="full-weight">Automatically Collected Information</p>
            <p>Information about your computer hardware and software may be automatically collected by Flights Forever. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Flights Forever website.</p>
            <p className="full-weight">Use of Cookies</p>
            <p>The Flights Forever website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
            <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Flights Forever pages, or register with Flights Forever site or services, a cookie helps Flights Forever to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Flights Forever website, the information you previously provided can be retrieved, so you can easily use the Flights Forever features that you customized.</p>
            <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Flights Forever services or websites you visit.</p>
            <p className="full-weight">Facebook Ads conversion tracking (Facebook pixel) (Facebook, Inc.)</p>
            <p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.Facebook Ads conversion tracking (Facebook pixel) is an analytics service provided by Facebook, Inc. that connects data from the Facebook advertising network with actions performed on this Application. The Facebook pixel tracks conversions that can be attributed to ads on Facebook, Instagram and Audience Network.
          </p>
            <p className="full-weight">Links</p>
            <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
            <p className="full-weight">Security of your Personal Information</p>
            <p>Flights Forever secures your personal information from unauthorized access, use, or disclosure. Flights Forever uses the following methods for this purpose:</p>
            <ul>
              <li>SSL Protocol</li>
            </ul>
            <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
            <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
            <p className="full-weight">Children Under Thirteen</p>
            <p>Flights Forever does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
            <p className="full-weight">E-mail Communications</p>
            <p>From time to time, Flights Forever may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Flights Forever or click on a link therein.</p>
            <p className="full-weight">Changes to this Statement</p>
            <p>Flights Forever reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
            <p className="full-weight">Contact Information</p>
            <p>Flights Forever welcomes your questions or comments regarding this Statement of Privacy. If you believe that Flights Forever has not adhered to this Statement, please contact Flights Forever at hello@flightsforever.com.</p>
            <p>Effective as of December 29, 2018</p>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}
