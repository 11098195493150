import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux";
function mapStateToProps(state) {
  return { auth: state.auth }
}
class Privateroute extends Component {
  constructor(props) {
    super(props);
    this.checkRole = this.checkRole.bind(this)
  }
  checkRole(props) {
    if (this.props.auth.isAuthenticated) return <this.props.component {...props} auth={this.props.auth} />
    else return <Redirect
      to={{
        pathname: "/",
        state: { from: props.location }
      }}
    />
  }
  render() {
    return (
      <Route
        path={this.props.path}
        render={(props) => this.checkRole(props)
        } />
    )
  }
}

export default connect(mapStateToProps)(Privateroute);
