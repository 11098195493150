import React, { Component } from 'react';
import { Card, Row, Col, Button, Form, Input, Select, Modal } from 'antd';
import axios from 'axios';
import jwt from 'jsonwebtoken';
// import Dropzone from 'react-dropzone';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Navbar from '../ui/navbar';
import Footer from '../ui/footer';
import apiUrl from '../../config';
import CheckoutForm from '../ui/checkout/update';
// import Check from '../ui/svg/check'

const stripePromise = loadStripe('pk_live_0ZPUbo6owqYEovmFEhaVUweb');

// const { Option, OptGroup } = Select;

export default class Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      fromCity: '',
      email: '',
      obj: '',
      id: '',
      stripe: sessionStorage.getItem('success') ? false : true,
      visible: false,
      show: false
    }
  }
  componentDidMount() {
    const href = new URL(window.location.href)
    const url = new URLSearchParams(href.search)
    const user = url.get('user');
    console.log(user)
    if (user) {
      const verified = jwt.verify(user, 'sguuysc6728298u9ch837883')
      if (verified) {
        const email = jwt.decode(user, 'sguuysc6728298u9ch837883');
        this.setState({ email: email.email, id: email.id, obj: email.obj ? email.obj : '' })
      }
    } else {
      window.location.assign(`/`)
    }
  }
  handleVisible() {
    this.setState({ visible: true })
  }
  handleHideVisible() {
    this.setState({ visible: false })
  }
  handleHideShow() {
    this.setState({ show: false })
  }
  handleShow() {
    this.setState({ show: true })
  }
  typing = (e) => {
    const name = e.target.value;
    this.setState({
      [name]: e.target.value
    })
  }
  // onDrop(files) {
  //   this.setState({ isLoading: true })
  //   files.forEach(file => {
  //     var formData = new FormData();
  //     formData.append("picture", file);
  //     axios.patch(`${apiUrl}/api/users/user/picture`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     }).then((res) => {
  //       if (res.data.picture) {
  //         console.log(res)
  //         localStorage.setItem("picture", res.data.picture);
  //         this.setState({ isLoading: false, success: true, picture: res.data.picture })
  //       } else {
  //         this.setState({ error: "Error uploading image" })
  //       }
  //     })
  //   });
  // }
  // handleChange = (value) => this.setState({ fromCity: value })
  render() {
    return (
      <div>
        <Navbar classPass="xnav inherit-pos" />
        {
          this.state.stripe === false ? (
            <section style={{ marginBottom: '0' }} className="container-full-faq">
              <div className="wid-6">
                <h1 className="h1-large text-color-pri mar-bottom-1 mar-t-0 text-center">Congrats!</h1>
                <div className="wid-8">
                  <p className="testimonials-text">Your card information has been updated and your membership is active.</p>
                  <p className="testimonials-text">There’s nothing further required, you’ll continue receiving your flight deals.</p>
                  {/* <p className="testimonials-text">Please make sure to add our email hello@flightsforever.com to your contact list so you never miss out on any emails.</p> */}
                  <p className="testimonials-text">Happy travels! <span aria-label="Love" role="img">❤️</span></p>
                </div>
              </div>
            </section>
          ) : (
              <section className="container-full-update section-white-grey">
                <div className="container pd-y-1">
                  <div className="px-15px">
                    <h1 className="h1-update text-center">Welcome!</h1>
                  </div>
                  <Row>
                    <Col className="px-15px" md={{ span: 12, offset: 6 }} xs={{ span: 24 }}>
                      <div>
                        <Card title="Billing Information">
                          <div>
                            <p className="text-center">Change your card information to prevent cancellation.</p>
                            <div className="flex-center">
                              <Button type="primary" onClick={this.handleVisible.bind(this)}>Update Card Information</Button>
                              <Modal
                                visible={this.state.visible}
                                footer={null}
                                onCancel={this.handleHideVisible.bind(this)}
                              >
                                <div className="wid-pay mt-1">
                                  <div className="example">
                                    <Elements stripe={stripePromise}>
                                      <CheckoutForm handleHideVisible={this.handleHideVisible.bind(this)} email={this.state.email} id={this.state.id} inId={this.state.obj} />
                                    </Elements>
                                  </div>
                                  <div className="mm-top-1">
                                    <img style={{ width: '100%' }} src="../images/secure-stripe-payment-logo.png" alt="" />
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Col>
                    {/* <Col md={{ span: 12 }} xs={{ span: 24 }} className="mb-1-res">
                <Row>
                  <Col className="px-15px" md={{ span: 24 }}>
                    <div>
                      <Card>
                        <p className="mb-15">Profile picture</p>
                        <div className="m-flex">
                          <img src="https://res.cloudinary.com/dj3dtt6rd/image/upload/v1557091263/avatar_ayaapx.jpg" className="img-edit-circle mr-1" alt="" />
                          <Dropzone
                            multiple={false}
                            accept="image/*"
                            onDrop={this.onDrop.bind(this)}
                            name="picture"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <Button {...getRootProps()} className="btn btn-primary">
                                <input {...getInputProps()} />
                                Change
                                </Button>
                            )}
                          </Dropzone>
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col className="px-15px mt-1" md={{ span: 24 }}>
                    <div>
                      <Card>
                        <h1>Here are your details.</h1>
                        <p className="mb-15">Edit your details.</p>
                        <Form>
                          <div className="input">
                            <label>EMAIL</label>
                            <Input type="email" name="email" value={this.state.email} onChange={this.typing}/>
                          </div>
                          <div className="mt-15 input">
                            <label>HOME AIRPORT</label>
                            <Select size="large" onChange={this.handleChange} className="re-input" placeholder="Choose airport">
                              <OptGroup label="Australia">
                                <Option value="Adelaide">Adelaide</Option>
                                <Option value="Brisbane">Brisbane</Option>
                                <Option value="Melbourne">Melbourne</Option>
                                <Option value="Perth">Perth</Option>
                                <Option value="Sydney">Sydney</Option>
                              </OptGroup>
                              <OptGroup label="Canada">
                                <Option value="Calgary">Calgary</Option>
                                <Option value="Edmonton" >Edmonton</Option>
                                <Option value="Montreal" >Montreal</Option>
                                <Option value="Ottawa" >Ottawa</Option>
                                <Option value="Toronto" >Toronto</Option>
                                <Option value="Vancouver" >Vancouver</Option>
                              </OptGroup>
                              <OptGroup label="Europe">
                                <Option value="Amsterdam">Amsterdam</Option>
                                <Option
                                  Option value="Athens" >Athens</Option><Option value="Barcelona" >Barcelona</Option><Option value="Basel" >Basel</Option><Option value="Belfast" >Belfast</Option><Option value="Berlin" >Berlin</Option><Option value="Brussels" >Brussels</Option><Option value="Copenhagen" >Copenhagen</Option><Option value="Dublin" >Dublin</Option><Option value="Dusseldorf" >Dusseldorf</Option><Option value="Edinburgh" >Edinburgh</Option><Option value="Frankfurt" >Frankfurt</Option><Option value="Geneva" >Geneva</Option><Option value="Hamburg" >Hamburg</Option><Option value="Helsinki" >Helsinki</Option><Option value="Lisbon" >Lisbon</Option><Option value="London" >London</Option><Option value="Madrid" >Madrid</Option><Option value="Manchester (UK)">Manchester (UK)</Option><Option value="Milan" >Milan</Option><Option value="Munich" >Munich</Option><Option value="Nice" >Nice</Option><Option value="Oslo" >Oslo</Option><Option value="Paris" >Paris</Option><Option value="Rome" >Rome</Option><Option value="Stockholm" >Stockholm</Option><Option value="Venice" >Venice</Option><Option value="Vienna" >Vienna</Option><Option value="Zurich" >Zurich</Option>
                              </OptGroup>
                              <OptGroup label="Alabama">
                                <Option value="Birmingham">Birmingham</Option>
                              </OptGroup>
                              <OptGroup
    label="Alaska"><Option
    value="Anchorage" >Anchorage</Option></OptGroup><OptGroup
    label="Arizona"><Option
    value="Phoenix" >Phoenix</Option><Option
    value="Tucson" >Tucson</Option></OptGroup><OptGroup
    label="Arkansas"><Option
    value="Little Rock" >Little Rock</Option></OptGroup><OptGroup
    label="California"><Option
    value="Los Angeles" >Los Angeles</Option><Option
    value="Oakland" >Oakland</Option><Option
    value="Sacramento" >Sacramento</Option><Option
    value='San Diego' >San Diego</Option><Option
    value='San Francisco' >San Francisco</Option></OptGroup><OptGroup
    label="Colorado"><Option
    value="Denver" >Denver</Option></OptGroup><OptGroup
    label="Connecticut"><Option
    value="Hartford" >Hartford</Option></OptGroup><OptGroup
    label='District of Columbia'><Option
    value='Washington D.C.' >Washington D.C.</Option></OptGroup><OptGroup
    label="Florida"><Option
    value='Fort Lauderdale' >Fort Lauderdale</Option><Option
    value="Miami" >Miami</Option><Option
    value="Orlando" >Orlando</Option><Option
    value="Tampa" >Tampa</Option></OptGroup><OptGroup
    label="Georgia"><Option
    value="Atlanta" >Atlanta</Option></OptGroup><OptGroup
    label="Hawaii"><Option
    value="Honolulu" >Honolulu</Option></OptGroup><OptGroup
    label="Idaho"><Option
    value="Boise" >Boise</Option></OptGroup><OptGroup
    label="Illinois"><Option
    value="Chicago" >Chicago</Option></OptGroup><OptGroup
    label="Indiana"><Option
    value="Indianapolis" >Indianapolis</Option></OptGroup><OptGroup
    label="Iowa"><Option
    value='Des Moines' >Des Moines</Option><Option
    value="Kansas" >Kansas</Option><Option
    value="Wichita" >Wichita</Option></OptGroup><OptGroup
    label="Kentucky"><Option
    value="Louisville" >Louisville</Option></OptGroup><OptGroup
    label="Louisiana"><Option
    value='New Orleans' >New Orleans</Option></OptGroup><OptGroup
    label="Maine"><Option
    value="Portland" >Portland</Option></OptGroup><OptGroup
    label="Maryland"><Option
    value="Baltimore" >Baltimore</Option></OptGroup><OptGroup
    label="Massachussets"><Option
    value="Boston" >Boston</Option></OptGroup><OptGroup
    label="Michigan"><Option
    value="Detroit" >Detroit</Option></OptGroup><OptGroup
    label="Minnesota"><Option
    value="Minneapolis" >Minneapolis</Option></OptGroup><OptGroup
    label="Mississippi"><Option
    value="Gulfport" >Gulfport</Option><Option
    value="Jackson" >Jackson</Option></OptGroup><OptGroup
    label="Missouri"><Option
    value='Kansas City' >Kansas City</Option><Option
    value='St. Louis' >St. Louis</Option></OptGroup><OptGroup
    label="Montana"><Option
    value="Billings" >Billings</Option><Option
    value="Bozeman" >Bozeman</Option></OptGroup><OptGroup
    label="Nebraska"><Option
    value="Omaha" >Omaha</Option></OptGroup><OptGroup
    label="Nevada"><Option
    value='Las Vegas' >Las Vegas</Option><Option
    value="Reno" >Reno</Option></OptGroup><OptGroup
    label='New Jersey'><Option
    value="Newark" >Newark</Option></OptGroup><OptGroup
    label='New Mexico'><Option
    value="Albuquerque" >Albuquerque</Option></OptGroup><OptGroup
    label='New York'><Option
    value="Buffalo" >Buffalo</Option><Option
    value='New York City' >New York City</Option></OptGroup><OptGroup
    label='North Carolina'><Option
    value="Charlotte" >Charlotte</Option><Option
    value="Raleigh" >Raleigh</Option></OptGroup><OptGroup
    label='North Dakota'><Option
    value="Fargo" >Fargo</Option></OptGroup><OptGroup
    label="Ohio"><Option
    value="Cincinnati" >Cincinnati</Option><Option
    value="Cleveland" >Cleveland</Option><Option
    value="Columbus" >Columbus</Option></OptGroup><OptGroup
    label="Oklahoma"><Option
    value='Oklahoma City' >Oklahoma City</Option><Option
    value="Tulsa" >Tulsa</Option></OptGroup><OptGroup
    label="Oregon"><Option
    value="Portland" >Portland</Option></OptGroup><OptGroup
    label="Pennsylvania"><Option
    value="Philadelphia" >Philadelphia</Option><Option
    value="Pittsburgh" >Pittsburgh</Option></OptGroup><OptGroup
    label='Rhode Island'><Option
    value="Providence" >Providence</Option></OptGroup><OptGroup
    label='South Carolina'><Option
    value="Charleston" >Charleston</Option><Option
    value="Columbia" >Columbia</Option><Option
    value='Myrtle Beach' >Myrtle Beach</Option></OptGroup><OptGroup
    label="Tennessee"><Option
    value="Memphis" >Memphis</Option><Option
    value="Nashville" >Nashville</Option></OptGroup><OptGroup
    label="Texas"><Option
    value="Austin" >Austin</Option><Option
    value="Dallas" >Dallas</Option><Option
    value='El Paso' >El Paso</Option><Option
    value="Houston" >Houston</Option><Option
    value='San Antonio' >San Antonio</Option></OptGroup><OptGroup
    label="Utah"><Option
    value='Salt Lake City' >Salt Lake City</Option></OptGroup><OptGroup
    label="Vermont"><Option
    value="Burlington" >Burlington</Option></OptGroup><OptGroup
    label="Virginia"><Option
    value="Norfolk" >Norfolk</Option><Option
    value="Richmond" >Richmond</Option></OptGroup><OptGroup
    label="Washington"><Option
    value="Seattle" >Seattle</Option><Option
    value="Spokane" >Spokane</Option></OptGroup><OptGroup
    label='West Virginia'><Option
    value='Charleston (WV)' >Charleston (WV)</Option></OptGroup><OptGroup
    label="Wisconsin"><Option
    value="Milwaukee" >Milwaukee</Option></OptGroup>
                            </Select>
                          </div>
                          <Button className="mt-1">Submit</Button>
                        </Form>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Row>
                  <Col className="px-15px" md={{ span: 24 }}>
                    <div>
                      <Card title="Update card details">
                        <div>
                          <p className="text-center">Update your subscription to prevent your account from being cancelled.</p>
                          <div className="flex-center">
                            <Button onClick={this.handleVisible.bind(this)}>Update subscription</Button>
                            <Modal
                              visible={this.state.visible}
                              footer={null}
                              onCancel={this.handleHideVisible.bind(this)}
                            >
                              <div className="wid-pay mt-1">
                                <h1 className="text-center h1-large-2-1 m-bottom-none full-weight">Try free for 14 days.</h1>
                                <h1 className="text-center h1-large-2-1 full-weight">$25/year after trial.</h1>
                                <div className="mm-top-1">
                                  <div className="list-pay-items">
                                    <Check/>
                                    <p className="mr-left-05">No charge until your trial ends.</p>
                                  </div>
                                  <div className="list-pay-items">
                                    <Check/>
                                    <p className="mr-left-05">6-month money-back guarantee.</p>
                                  </div>
                                  <div className="list-pay-items">
                                    <Check/>
                                    <p className="mr-left-05">Cancel anytime.</p>
                                  </div>
                                </div>
                                <StripeProvider apiKey="pk_live_0ZPUbo6owqYEovmFEhaVUweb">
                                  <div className="example">
                                    <Elements>
                                      <CheckoutForm />
                                    </Elements>
                                  </div>
                                </StripeProvider>
                                <div className="mm-top-1">
                                  <img style={{ width: '100%' }} src="../images/secure-stripe-payment-logo.png" alt=""/>
                                </div>
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col className="px-15px mt-1" md={{ span: 24 }}>
                    <div>
                      <Card title="Not getting a good service">
                        <div>
                          <p className="text-center">Cancel your subscription</p>
                          <div className="flex-center">
                            <Button type="danger" onClick={this.handleShow.bind(this)}>Cancel subscription</Button>
                            <Modal
                              visible={this.state.show}
                              footer={null}
                              onCancel={this.handleHideShow.bind(this)}
                            >
                              <div>

                              </div>
                            </Modal>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Col> */}
                  </Row>
                </div>
              </section>
            )
        }
        <Footer />
      </div>
    )
  }
}
