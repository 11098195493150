import React, { Component } from 'react';
import axios from 'axios';
import apiUrl from '../../../config';
import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import { Button } from 'antd';

class InjectedCheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      inComplete: true,
      loading: false,
      error: ''
    }
    this.submit = this.submit.bind(this);
  }
  async submit(ev) {
    this.setState({ loading: true })
    const { stripe, elements } = this.props;
    const { paymentMethod, error } = await stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })
      console.log(error)
    if(paymentMethod) {
      axios.post(`${apiUrl}/api/users/user/update/pay`, { payment_method: paymentMethod.id, id: this.props.id, inId: this.props.inId })
        .then((res) => {
          console.log(res)
          this.setState({ complete: true, loading: false, inComplete: true })
          sessionStorage.setItem('success', 'true')
          this.props.handleHideVisible()
          window.location.reload()
        })
        .catch((e) => {
          console.log(e.response.data)
          this.setState({ inComplete: false, loading: false, error: 'Card failed, try again.', complete: false })
        })
    } else {
      this.setState({ error: 'Card failed, try again.', inComplete: false, loading: false, complete: false })
    }
  }
  render() {
    return (
      <div className="checkout mt-3">
        {this.state.inComplete === false ? <h1 className="text-center">{this.state.error}</h1> : null}
        {this.state.complete === true ? <h1 className="text-center">Card change was successful</h1> : null}
        <CardElement />
        <div className="flex-center">
          <Button loading={this.state.loading} className="btn-stripe" onClick={this.submit}>UPDATE</Button>
        </div>
      </div>
    );
  }
}

const CheckoutForm = ({ id }) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <InjectedCheckoutForm stripe={stripe} id={id} elements={elements} />
    )}
  </ElementsConsumer>
);

export default CheckoutForm;
