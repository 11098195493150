import React, { Component, Fragment } from "react";
import { Button, Rate, Row, Col } from "antd";
import { Helmet } from "react-helmet";
import Navbar from "../ui/navbar";
import Footer from "../ui/footer";
import Signup from "../ui/authmodals/signup";

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: false,
      show2: false,
      authenticated: JSON.parse(localStorage.getItem("authenticated")),
    };
    this.onShow = this.onShow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onShow1 = this.onShow1.bind(this);
    this.onClose1 = this.onClose1.bind(this);
    this.onShow2 = this.onShow2.bind(this);
    this.onClose2 = this.onClose2.bind(this);
    this.onCloseToForgot = this.onCloseToForgot.bind(this);
    this.onCloseToSignup = this.onCloseToSignup.bind(this);
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
  }
  onShow() {
    this.setState({ show: true });
    document.body.className = "modal--active";
  }
  onClose() {
    this.setState({ show: false });
    document.body.className = "";
  }
  onCloseToForgot() {
    this.setState({ show: false, show2: true });
    document.body.className = "modal--active";
  }
  onCloseToSignup() {
    this.setState({ show: false, show1: true });
    document.body.className = "modal--active";
  }
  onShow1() {
    const element = document.getElementById("ForScroll");
    element.scrollIntoView({ block: "start", behavior: "smooth" });
    setTimeout(() => {
      this.setState({ show1: true });
      document.body.className = "modal--active";
    }, 1000);
  }
  onClose1() {
    this.setState({ show1: false });
    document.body.className = "";
  }
  onShow2() {
    this.setState({ show2: false });
    document.body.className = "modal--active";
  }
  onClose2() {
    this.setState({ show2: false });
    document.body.className = "";
  }
  login() {
    this.setState({ authenticated: true });
    localStorage.setItem("authenticated", "true");
  }
  logout() {
    this.setState({ authenticated: false });
    localStorage.clear();
  }
  render() {
    return (
      <div id="ForScroll">
        <Helmet>
          <title>
            Flights Forever | Cheap Flight Deals to Dream Destinations
          </title>
          <meta
            name="description"
            content="The deals I’m getting from Flights Forever are incredible. My partner and I will travel so much more because of the savings."
          />
        </Helmet>
        <Navbar
          classPass={
            this.state.authenticated ? "greatest-index-1 xnav" : "xnav"
          }
          authenticated={this.state.authenticated}
          onToggleShow={this.onShow}
          logout={this.logout}
        />
        <Fragment>
          <section className="container-align">
            {this.state.show1 ? <Signup onClose={this.onClose1} /> : null}
            <div className="form-wrapper-left">
              <div className="illustration">
                <img
                  src="https://images.squarespace-cdn.com/content/v1/544ff970e4b0c2f7a273e9b6/1537085873534-5M1OU99I8QRVGL4K1DJX/ke17ZwdGBToddI8pDm48kFQQgP34qnCpeHaeAOzTt7pZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PIvwpK0aFuhG0GtLLHqvbV4raqY38tdDiF-KTEvoUH9G4/Travel_icons_v4.gif?format=2500w"
                  alt="Illusration"
                />
                {/* <iframe src="https://giphy.com/embed/toelXGUsYD6vtCN408" width="480" height="360" frameBorder="0" class="giphy-embed" allowFullScreen></iframe> */}
              </div>
            </div>
            <div className="form-wrapper">
              <div className="flex-wrapper">
                <div className="full-width pad-sep-3">
                  <div className="mar-sep-3">
                    <h1 className="works-left h1-large text-color-pri mar-bottom-1">
                      Save hundreds of dollars per flight ticket
                    </h1>
                    <p className="works-left p-large-1">
                      Get email alerts for cheap flights departing from{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          fontStyle: "italic",
                        }}
                      >
                        your city
                      </span>
                      .
                    </p>
                    <div className="flex-btn">
                      <a
                        style={{ fontWeight: "600" }}
                        className="ant-btn ant-btn-lg mar-03 btn-recolor-nav"
                        href="#about"
                      >
                        LEARN MORE
                      </a>
                      <Button
                        onClick={this.onShow1}
                        size="large"
                        color="blue"
                        className="mar-03 btn-recolor-pri"
                      >
                        TRY IT FREE!
                      </Button>
                    </div>
                    <div className="featured">
                      <img
                        className="featured-img"
                        src="../images/J.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container-align-mobile d-color">
            {this.state.show1 ? <Signup onClose={this.onClose1} /> : null}
            <div className="flex-wrapper">
              <div style={{ maxWidth: "601px" }} className="pad-sep-3">
                <div className="mar-sep-3-1">
                  <h1 className="text-center h1-large text-color-pri mar-bottom-1">
                    Save hundreds of dollars per flight ticket
                  </h1>
                  <p className="text-center p-large-1">
                    Get email alerts for cheap flights departing from{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        fontStyle: "italic",
                      }}
                    >
                      your city
                    </span>
                    .
                  </p>
                  <div className="full-width">
                    <div className="flex-center">
                      <a
                        style={{ fontWeight: "600" }}
                        className="ant-btn ant-btn-lg mar-03 btn-recolor-nav"
                        href="#about"
                      >
                        LEARN MORE
                      </a>
                      <Button
                        onClick={this.onShow1}
                        size="large"
                        color="blue"
                        className="mar-03 btn-recolor-pri"
                      >
                        TRY IT FREE!
                      </Button>
                    </div>
                    <div className="featured">
                      <img
                        className="featured-img"
                        src="../images/J.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-about landing-shadow" id="about">
            <h1 className="text-center h1-large text-color-pri mar-bottom-1">
              Here's How It Works
            </h1>
            <div style={{ width: "90%" }}>
              <Row className="ant-row-res display-align-row">
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 10, offset: 2 }}
                >
                  <div>
                    <img
                      className="img-pp"
                      style={{ width: "100%", height: "100%" }}
                      src="../images/img1.jpeg"
                      alt=""
                    />
                    <img
                      className="img-ppp"
                      style={{ width: "100%", height: "100%" }}
                      src="../images/img2.jpeg"
                      alt=""
                    />
                    {/* <img src="../images/iPhone.png" style={{ width: '100%', height: '100%' }} alt=""/> */}
                  </div>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div>
                    <div style={{ marginTop: "2rem" }} className="flex">
                      <div>
                        <img
                          className="img-how-res"
                          src="../images/www.png"
                          alt=""
                        />
                      </div>
                      <div className="pd-l-1">
                        <h2 className="works-left heavy black">Cheap Deals</h2>
                        <p className="works-left">
                          Choose your home airport and we will search daily for
                          the cheapest domestic & international flight deals to
                          dream destinations.
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "2rem" }} className="flex">
                      <div>
                        <img
                          className="img-how-res"
                          src="../images/passport.png"
                          alt=""
                        />
                      </div>
                      <div className="pd-l-1">
                        <h2 className="works-left heavy black">
                          Book Directly
                        </h2>
                        <p className="works-left">
                          Once we find amazing flight deals, we will quickly
                          email them to you with all the info needed to book the
                          ticket directly with the airline.
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "2rem" }} className="flex">
                      <div>
                        <img
                          className="img-how-res"
                          src="../images/sun-umbrella.png"
                          alt=""
                        />
                      </div>
                      <div className="pd-l-1">
                        <h2 className="works-left heavy black">
                          Vacation Time
                        </h2>
                        <p className="works-left">
                          Book your favorite deal, save money, and enjoy your
                          dream vacation!
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="flex-center margin-top-4-res">
              <Button
                onClick={this.onShow1}
                size="large"
                color="blue"
                className="mar-03 btn-recolor-pri btn-extra-large"
              >
                TRY FOR FREE
              </Button>
            </div>
          </section>
          <section className="pd d-color">
            <div className="flex-wrapper">
              <div>
                <h1 className="text-center h1-large text-color-pri">
                  Deals Our Members Received
                </h1>
              </div>
              <div className="example-table1">
                <div className="example-table__cell1">
                  <img
                    className="full-width full-height"
                    src="../images/image.jpg"
                    alt=""
                  />
                </div>
                <div className="example-table__cell1">
                  <img
                    className="full-width full-height"
                    src="../images/image 2.jpg"
                    alt=""
                  />
                </div>
                <div className="example-table__cell1">
                  <img
                    className="full-width full-height"
                    src="../images/image 3.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex-center margin-top-4">
                <Button
                  onClick={this.onShow1}
                  size="large"
                  color="blue"
                  className="mar-03 btn-recolor-pri btn-extra-large"
                >
                  GET THE DEALS
                </Button>
              </div>
            </div>
          </section>
          <section className="pd landing-shadow">
            <div className="flex-wrapper">
              <div>
                <h1 className="text-center h1-large text-color-pri">
                  Why Members Love Us
                </h1>
                <div>
                  <p
                    style={{ fontSize: "1.5rem", marginBottom: "0" }}
                    className="text-center"
                  >
                    Avg. 5 stars (246+ reviews)
                  </p>
                  <Rate
                    style={{ marginBottom: "1rem" }}
                    className="flex-btn"
                    disabled
                    defaultValue={5}
                  />
                </div>
              </div>
              <div className="example-table">
                <div className="example-table__cell">
                  <div className="realign-img-t">
                    <p className="p-deals">Sumeya</p>
                    <img
                      className="img-circle"
                      src="../images/Sumeya.jpg"
                      alt=""
                    />
                  </div>
                  <p className="m-bottom-none">
                    Crazy! I just booked a roundtrip deal to Paris for $266!!
                    I'm so excited for this solo trip and happy I snatched this
                    deal before it disappeared.
                  </p>
                  <Rate disabled defaultValue={5} />
                </div>
                <div className="example-table__cell">
                  <div className="realign-img-t">
                    <p className="p-deals">Misa</p>
                    <img
                      className="img-circle"
                      src="../images/Misa.jpg"
                      alt=""
                    />
                  </div>
                  <p className="m-bottom-none">
                    I’m always looking for my next adventure and Flights Forever
                    is the perfect service for my future travel plans!
                  </p>
                  <Rate disabled defaultValue={5} />
                </div>
                <div className="example-table__cell">
                  <div className="realign-img-t">
                    <p className="p-deals">Didi</p>
                    <img
                      className="img-circle"
                      src="../images/Didi.jpg"
                      alt=""
                    />
                  </div>
                  <p className="m-bottom-none">
                    I just booked a deal for my family of 4 to Spain and paid
                    $324 per ticket! We saved a TON of money and look forward to
                    our trip.
                  </p>
                  <Rate disabled defaultValue={5} />
                </div>
                <div className="example-table__cell">
                  <div className="realign-img-t">
                    <p className="p-deals">Dennis</p>
                    <img
                      className="img-circle"
                      src="../images/Dennis.jpg"
                      alt=""
                    />
                  </div>
                  <p className="m-bottom-none">
                    I enjoy seeing the flight deals and thinking of all the
                    possibilities. Amazing service, I look forward to booking a
                    deal soon.
                  </p>
                  <Rate disabled defaultValue={5} />
                </div>
                <div className="example-table__cell">
                  <div className="realign-img-t">
                    <p className="p-deals">Ayan</p>
                    <img className="img-circle" src="../images/2.jpg" alt="" />
                  </div>
                  <p className="m-bottom-none">
                    The deals I’m getting from Flights Forever are incredible.
                    My partner and I will travel so much more because of the
                    savings.
                  </p>
                  <Rate disabled defaultValue={5} />
                </div>
                <div className="example-table__cell">
                  <div className="realign-img-t">
                    <p className="p-deals">Hidaya</p>
                    <img
                      className="img-circle"
                      src="../images/Hidaya.jpeg"
                      alt=""
                    />
                  </div>
                  <p className="m-bottom-none">
                    I love the ease of being sent great deals and saving time
                    instead of searching for them myself. I recommend FF to
                    everyone!
                  </p>
                  <Rate disabled defaultValue={5} />
                </div>
              </div>
            </div>
          </section>
          <section className="pd d-color">
            <div className="flex-wrapper">
              <div>
                <h1 className="text-center h1-large text-color-pri">
                  Made by Travel Junkies
                </h1>
              </div>
              <div className="not-full">
                <Row className="display-align">
                  <Col className="col-adjust" md={10} sm={24}>
                    <img
                      className="full-width image-full mb-1-res border-image"
                      src="../images/giraf.jpeg"
                      alt="Mariam"
                    />
                  </Col>
                  <Col
                    style={{ backgroundColor: "white" }}
                    className="back-shadow padding-1-imp bord-rad col-adjust"
                    md={14}
                    sm={24}
                  >
                    <div className="full-width pad-1-sec">
                      <h1
                        style={{
                          fontSize: "1.6rem",
                          color: "rgba(0, 0, 0, 0.65)",
                        }}
                        className="text-right work-align-text"
                      >
                        Hi, we’re Mariam & Ahmed!
                      </h1>
                      <div className="testimonials">
                        <p className="p-large">
                          We’re the sibling duo and founders of Flights Forever.
                        </p>
                        <p className="p-large">
                          We both work professional demanding jobs and love to
                          travel, but also love deals that saves us a ton of
                          money. We’ve booked amazing flight deals for the past
                          5+ years, embarked on adventures in 24 countries, and
                          saved hundreds of dollars per flight ticket.
                        </p>
                        <p className="p-large">
                          For example, we booked ROUNDTRIP deals to Iceland for
                          $279 (usually $800+), Spain for $325 (usually $900+),
                          Kenya for $551 (usually $1200+), and more — creating
                          some of the most incredible memories!
                        </p>
                        <p className="p-large">
                          We hunt through multiple travel sites to find deep
                          discounted sales or mistake fares, but they disappear
                          quickly. That's why we built Flights Forever for busy
                          professionals like you, so you never miss out on the
                          deals and save time.
                        </p>
                        <p className="p-large">
                          Our goal is to help you fulfill your travel dreams,
                          make friends, create lifetime memories, and come home
                          refreshed.
                        </p>
                        <p className="p-large">
                          We look forward to having you join us!{" "}
                          <span aria-label="Love" role="img">
                            ✈️
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div>
                  <h1
                    style={{
                      marginTop: "2rem",
                      marginBottom: "1.5rem",
                      color: "rgba(0, 0, 0, 0.65)",
                    }}
                    className="text-center h1-large"
                  >
                    Ready to try for free?
                  </h1>
                </div>
                <div className="flex-center">
                  <Button
                    onClick={this.onShow1}
                    size="large"
                    color="blue"
                    className="mar-03 btn-recolor-pri btn-extra-large"
                  >
                    SIGN ME UP!
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
        <Footer />
      </div>
    );
  }
}
